.p-t-lg { padding-top: 20px; }
.p-r-lg { padding-right: 20px; }
.p-b-lg { padding-bottom: 20px; }
.p-l-lg { padding-left: 20px; }

.p-md { padding: 15px; }
.p-t-md { padding-top: 15px; }
.p-r-md { padding-right: 15px; }
.p-b-md { padding-bottom: 15px; }
.p-l-md { padding-left: 15px; }

.p-t-xs { padding-top: 5px; }
.p-r-xs { padding-right: 5px; }
.p-b-xs { padding-bottom: 5px; }
.p-l-xs { padding-left: 5px; }

.p-none { padding: 0; }
.p-t-none { padding-top: 0; }
.p-r-none { padding-right: 0; }
.p-b-none { padding-bottom: 0; }
.p-l-none { padding-left: 0; }

.m-t-lg { margin-top: 20px; }
.m-r-lg { margin-right: 20px; }
.m-b-lg { margin-bottom: 20px; }
.m-l-lg { margin-left: 20px; }

.m-t-md { margin-top: 15px; }
.m-r-md { margin-right: 15px; }
.m-b-md { margin-bottom: 15px; }
.m-l-md { margin-left: 15px; }

.m-t-sm { margin-top: 10px; }
.m-r-sm { margin-right: 10px; }
.m-b-sm { margin-bottom: 10px; }
.m-l-sm { margin-left: 10px; }

.m-t-xs { margin-top: 5px; }
.m-r-xs { margin-right: 5px; }
.m-b-xs { margin-bottom: 5px; }
.m-l-xs { margin-left: 5px; }

.m-none { margin: 0; }
.m-t-none { margin-top: 0; }
.m-r-none { margin-right: 0; }
.m-b-none { margin-bottom: 0; }
.m-l-none { margin-left: 0; }
