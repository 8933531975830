@import "node_modules/bootstrap/less/bootstrap";

@import "./../../../vendor/laravel/spark/resources/assets/less/spark";

// Override defaults
@import "spark/variables";

body{
	color: black;
	background-color: @body-bg;
}

.navbar i,
.btn i{
	margin-right: 5px;
}

.navbar{

	background-color: @brand-primary;
	height: 50px;

	.navbar-brand,
	.navbar-nav > li > a{

		background: @navbar-link-bg;
		color: @navbar-link-color;
		font-size: 14px;
		transition: color @transition-time-default;
		padding-top: 10px;
		padding-bottom: 10px;
		height: 50px;

		&:hover{
			color: @navbar-link-hover-color;
			background: @navbar-link-bg;
		}

		@media only screen and (min-width: 768px) and (max-width:992px){
			font-size: 0; // Just show icons
		}

		i{
			font-size: 14px;
		}

	}


}

.icon-bar {
	background-color: @navbar-link-color !important;
}

.navbar-collapse{
	background-color: @brand-primary;
}

.spark-nav-profile-photo{
	height: 30px;
	width: 30px;
}


@media(min-width: 768px){

	.container-fluid{
		padding-left: 25px;
		padding-right: 25px;
	}

	.container > .navbar-header{
		height: 50px;
	}

}


.infoCard__time{
	margin-bottom: 0;
}

.infoCard.infoCard--appointment{
	margin-bottom: 0px;
}

.infoCard__title{
	margin-bottom: 0;
}

.infoCard__service-list{
	margin-bottom: 0;
	font-size: 13px;
}

textarea.form-control{
	resize: vertical;
}

td p,
td address{
	margin-bottom: 5px;
}

td p:last-of-type,
td address:last-of-type,{
	margin-bottom: 0;
}

.emptyState{
	text-align: center;
}

.emptyState__icon{
	font-size: 100px;
	opacity: .5;
	margin-bottom: 20px;
}

.panel{

	.panel-body{

		h2:first-of-type,
		h3:first-of-type{
			margin-top: 0;
		}

	}

	&.panel-primary{

		.panel-heading{
			background: @brand-primary;
			color: @text-on-primary-bg;
		}

	}

}

.nav-tabs > li > a{
	padding: 10px 12px
}

// Image previews used on forms
.img-preview{
	margin-bottom: @spacing-small;
}
